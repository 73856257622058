.B2Digi-header {
  background-color: #765623; /* Light grey background */
  margin: 0;
  width: 100%;
  height: 35px;
  text-align: left; /* Center the title text */
  display: flex;
  padding-left: 2rem;
  box-sizing: border-box;
  align-items: center;
  gap: 8px;
}

.B2Digi-header p {
    margin: 0; /* Remove default paragraph margin */
    padding: 5px;
    color: #ffffff; 
  }


.B2Digi-content {
margin: 30px;

}

.B2DInput {
  width: 100%;
}

.B2DSubmit {
  margin-top: 20px;
  padding: 12px 16px 12px 16px;

}