/* Default Styles */
.HomeContent {
}

.HomeBlocks {
    display: flex;
    max-width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    gap: 3%;
    min-height: 98rem;
}

.HomeBlockLeft {
    display: flex;
    flex-direction: column;
    width: 25%;
    gap: 2.75rem;
    height: 90rem;
}

.HomeBlockMid {
    display: flex;
    flex-direction: column;
    width: 41%;
    gap: 2.75rem;
    height: 90rem;
}

.HomeBlockRight {
    display: flex;
    flex-direction: column;
    width: 28%;
    gap: 2.75rem;
    height: 90rem;
}

.back-to-top-button {
    position: fixed;
    bottom: 50vh;
    right: 10px;
    height: 80px;
    width: 80px;
    font-size: 12px;
    z-index: 1000;
    background-image: url('/public/ScrollTop.png'); /* Your image path */
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    cursor: pointer;
  
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black; /* Adjust text color as needed */
}

/* Additional styling to adjust the text position */
.back-to-top-text {
    padding-top: 40px; /* Adjust as needed to move the text lower */
}

/* Media Query for screens 1500px and below */
@media (max-width: 1500px) {
    .mySwiper {
        min-width: 1350px;
    }
    .HomeBlocks {
        min-width: 1350px;
        margin-left: 5vw;
        margin-right: 5vw;
    }

    .HomeBlockLeft, .HomeBlockMid, .HomeBlockRight {
        width: auto; /* Adjust the widths as needed */
        max-width: 400px; /* Set a maximum width for the blocks */
        flex: 1;
        height: auto; /* Set a fixed height or adjust as needed */
    }

    .HomeBlockLeft {
        max-width: 300px; /* Example fixed width */
    }

    .HomeBlockMid {
        max-width: 600px; /* Example fixed width */
    }

    .HomeBlockRight {
        max-width: 400px; /* Example fixed width */
    }
}
