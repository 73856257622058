.book-detail {
    display: flex;
}

.book-detail img {
    display: flex;
    width: 16rem;
    height: 25rem;
    align-items: flex-start;
    gap: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* X-offset, Y-offset, Blur radius, Color */
}

.bookTitle{
    color: var(--Body-Text-Body-Color, #1A1A1A);

    /* Text Shadow */
    text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.20);

    /* Heading/H1 */
    font-family: Recoleta;
    font-size: 40px;
    font-style: normal;
    letter-spacing: 0.8px;
    text-transform: capitalize;
    margin: 0;
}

.NameNAuthor {
    color: var(--Body-Text-Body-Color, #1A1A1A);

    /* Text Shadow */
    text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.20);
    display: flex;
    margin-bottom: 4px;
    margin-top: 4px;
    
}

.book-detail-info {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0 58px
}

.book-detail-tags {
    margin: 8px 0 8px 0px;
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.actions {
    display: flex;
    flex-direction: row;
    gap: 8px;
}


.citation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto;
}

.citation-content {
  background: white;
  padding: 40px 80px 40px 40px;
  border-radius: 2px;
  text-align: center;
  width: 80%;
  max-width: 600px;
  display: flex;
  flex-direction: row; /* Align elements horizontally */
  align-items: flex-start;
  position: relative; /* Make the container relative to position the X button */
}

.close-citation-button {
  position: absolute; /* Position the button absolutely */
  top: 10px;
  right: 10px;
  padding: 6px 12px;
  border: none;
  background: #00000090;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.citation-styles {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  justify-content: flex-start;
  margin-right: 20px; /* Space between buttons and citation text */
}

.citation-styles button {
  padding: 20px 40px;
  margin: 10px 0; /* Add margin to separate buttons vertically */
  border: none;
  background: #f0f0f0;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
}

.citation-styles button.active {
  background: #00000090;
  color: white;
}

.citation-text {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
  text-align: left;
  border-style: solid;
  border-color: rgba(128, 128, 128, 0.428);
  border-width: 3px;
  box-shadow: 1px 1px 5px rgba(56, 60, 61, 0.267);
  padding: 20px;
}

.citation-right {
  display: flex;
  flex-direction: column; /* Stack text and button vertically */
  align-items: flex-start;
  flex-grow: 1;
}

.copy-citation-button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background: #00000090;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  align-self:center; /* Align button to the right */
}

  