/* RelatedDataVisualizations.css */
.Related-data-wrapper {
    display: flex;
    align-items: center;
}


.Related-data-container {
    flex-grow: 1;
    overflow-x: auto;
    gap: 1rem;
    white-space: nowrap;
    display: flex;
    width: 100%;
}

.nav-button {
    flex: 0 0 auto;
    background-color: transparent;
    border: none;
    color: #000; /* Adjust the color as needed */
    cursor: pointer;
    transition: color 0.3s ease;
    font-size: 30px;
}

.nav-button:hover {
    color: #555; /* Darker shade on hover */
}

.Related-data {
    margin-right: 20px;
    flex-basis: 18%; /* Adjust width as needed */
    text-align: left;
}

.Related-data img {
    width: 12rem;
    height: 12rem;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.Related-data-info {
    padding: 2px 0;
    display: flex;
    flex-direction: column;
    width: 150px;
    text-wrap: wrap;
}

.Related-data h3 {
    margin: 10px 0;
    font-size: 1.2em;
    text-wrap: wrap;
    text-align: left;
    display: inline-block;
}

.Related-data p {
    margin: -2px 0;
    margin-bottom: 0.5rem;
    font-size: 0.9em;
    color: #666; /* Grey text for publication date and author name */
}

.Related-data-container::-webkit-scrollbar {
    margin-top: 20px;
    height: 5px;
    opacity: 0;
}

.Related-data-container:hover::-webkit-scrollbar {
    opacity: 1;
}

/* FontAwesome import */
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
