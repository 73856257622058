/* SearchBar.css */
.search-container {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #ccc; 
    width: 100%;
    
  }
  
  .search-input {
    border: none;
    outline: none;
    font-size: 1rem;
    flex-grow: 1; 

    background-color: transparent;


  }
  
  .search-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    font-size: 1em;
    color: #ccc; /* Color of the icon */
  }
  
  
  .search-input:focus, .search-button:focus {
    outline: none;
  }
  

  .search-button:hover {
    color: #333;
  }
  
  /* FontAwesome import */
  @import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
  

  