/* PopularNovels.css */


.popular-topics {
    margin-top: 3rem;
    display:flex;
    flex-direction: column;
    gap: 2rem;
}

.popular-novels-wrapper {
    display: flex;
    align-items: center;
}

.section-title {
    display: flex;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    
    margin: 0;

    color: var(--Body-Text-Body-Color, #1A1A1A);

/* Text Shadow */
text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.20);

/* Heading/H5 */
font-family: Recoleta;
font-size: 1.25rem;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 1.5rem */
letter-spacing: 0.0125rem;
}

.topic-buttons-container-nov-gen {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;

    color: var(--Body-Text-Body-Color, #1A1A1A);

/* Small Shadow */
text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

/* Body/Regular */
font-family: Avenir;
font-size: 1rem;
font-style: normal;
font-weight: 350;
line-height: 130%; /* 1.3rem */
letter-spacing: 0.05rem;

}

.popular-novels-container {
    flex-grow: 1;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    width: 100%;
    gap: 1.5rem;
    justify-content: space-between;
}

.nav-button-pop {
    flex: 0 0 auto;
    background-color: transparent;
    border: none;
    color: #000; /* Adjust the color as needed */
    cursor: pointer;
    transition: color 0.3s ease;
    font-size: 30px;

}

.nav-button-pop:hover {
    color: #555; /* Darker shade on hover */
  }

.lft-btn {

    margin-left: -30px;
}

.rt-btn {
    margin-left: 10px;
}

.popular-novel {
    padding-right: 8px;
    text-align: left;
}

.popular-novel img {
    width: 12rem;
    height: 18.5rem;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.popular-novel-info {
    padding: 2px 0;
    display: flex;
    flex-direction: column;
    width: 12rem;
    text-wrap: wrap;
}

.popular-novel h3 {
    margin: 10px 0;
    font-size: 1.2em;
    text-wrap: wrap;
    text-align: left;
    display: inline-block;
}


.popular-novel p {
    color: var(--Body-Text-Body-Color, #1A1A1A);

    /* Body/Italic */
    font-family: Avenir;
    font-size: 1rem;
    font-weight: 350;
    line-height: 130%; /* 1.3rem */
    letter-spacing: 0.05rem;
    padding: 0;
    margin: 0;
}


.popular-novels-container::-webkit-scrollbar {
    margin-top: 20px;
    height: 5px;
    opacity: 0;
}

.popular-novels-container:hover::-webkit-scrollbar{
   opacity: 1;
}
/*
.popular-novels-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.popular-novels-container::-webkit-scrollbar-thumb {
    background: #888;
    opacity: 0;
}

.popular-novels-container::-webkit-scrollbar-thumb:hover {
    background: #555;
    opacity: 1;
}

*/

/* FontAwesome import */
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');