.custom-results-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  
  .custom-header {
    font-weight: bold;
    padding: 12px 15px;
    text-align: left;
    border-bottom: 2px solid #ddd;
  }
  
  .custom-row {
    border-bottom: 1px solid #ddd;
  }
  
  .custom-cell {
    padding: 12px 15px;
    text-align: left;
  }
  
  .custom-link {
    color: black;
    text-decoration: none;
    display: inline-block; /* Ensures ellipsis works */
    max-width: 100%; /* Constrain the width */
    overflow: hidden; /* Handle overflow */
    white-space: nowrap; /* Prevent text wrapping */
    text-overflow: ellipsis; /* Add ellipsis */
  }
  
  .custom-link:hover {
    text-decoration: underline;
  }
  
  .text-ellipsis--4 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%; /* Ensure it fits within the cell */

  }
  
  
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination {
    display: flex;
    list-style-type: none;
    padding: 0;
  }
  
  .pagination-button {
    padding: 8px 12px;
    margin: 0 4px;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    cursor: pointer;
    color: #765623;
  }
  
  .pagination-button.active {
    border: 1px solid var(--Theme-Primary, #765623);
    background: var(--Theme-Primary, #765623);
    color: white;
  }
  
  .pagination-button:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
  
  .pagination-button:hover:not(.active):not(:disabled) {
    background-color: #ddd;
  }
  
.collection-detail {
    
}

.caci-tags-container {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 4.44rem;
  margin-top: -10px;
}

.collection-detail-holder {
    display: flex;
    flex-direction: column;
}

.collection-detail-holder-left {
  display: flex;
  width: 48.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
}

.collection-detail-holder-right {
  display: flex;
  width: 18.5rem;
  padding: 1.5rem 1.25rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;

  border-top: 10px solid var(--Theme-Primary, #765623);
border-right: 1px solid var(--Theme-Primary, #765623);
border-bottom: 1px solid var(--Theme-Primary, #765623);
border-left: 1px solid var(--Theme-Primary, #765623);
background: var(--Theme-Day-background, #EDEDE7);
}

.colect-l-r-wrapper{
    display: flex;
    gap:1rem;
}

.colect-l-r-wrapper a {
  color: var(--Theme-Primary, #765623);

/* Body/Regular */
font-family: Avenir;
font-size: 1rem;
font-style: normal;
font-weight: 350;
line-height: 130%; /* 1.3rem */
letter-spacing: 0.05rem;
text-decoration-line: underline;
}

.colect-l-r-wrapper p {
  margin: 0;
}

.caci-intro {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}

.caci-intro p {
  margin: 0;
}

.caci-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.caci-innerheading {
  display: flex;
  gap: 1rem;

}

.caci-innerheading p {
  margin: 0;
}

.caci-submit-form {
  border-radius: 0.2rem;
  border: 1px solid var(--Theme-Primary, #765623);
  background: var(--Theme-Primary, #765623);
  display: flex;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  color: #FFFFFF;

  color: var(--Gray-White, #FFF);

  /* Body/Small */
  font-family: Avenir;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: 0.05rem;

  cursor: pointer;
}

.caci-detail-icon-name {
  color: var(--Theme-Primary, #765623);

/* Heading/H6 */
font-family: Avenir;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 1.2rem */
letter-spacing: 0.2rem;
text-transform: uppercase;
}

.pod-title {
  color: var(--Body-Text-Body-Color, #1A1A1A);

/* Text Shadow */
text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.20);

/* Heading/H1 */
font-family: Recoleta;
font-size: 2.5rem;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 3rem */
letter-spacing: 0.05rem;
text-transform: capitalize;
}