/* RelatedBriefs.css */
.Related-briefs-wrapper {
    display: flex;
    align-items: center;
}

.Related-brief img{
    width: 12rem;
    height: 12rem;
}

.Related-briefs-container {
    flex-grow: 1;
    overflow-x: auto;
    gap: 1rem;
    white-space: nowrap;
    display: flex;
    width: 100%;
    scroll-behavior: smooth;
}

.nav-button {
    flex: 0 0 auto;
    background-color: transparent;
    border: none;
    color: #000; 
    cursor: pointer;
    transition: color 0.3s ease;
    font-size: 30px;
}

.nav-button:hover {
    color: #555; /* Darker shade on hover */
}

.Related-brief {
    margin-right: 20px;
    flex-basis: 18%; 
    text-align: left;
}

.Related-brief-info {
    padding: 2px 0;
    display: flex;
    flex-direction: column;
    width: 150px;
    text-wrap: wrap;
}

.Related-brief h3 {
    margin: 10px 0;
    font-size: 1.2em;
    text-wrap: wrap;
    text-align: left;
    display: inline-block;
}

.Related-brief p {
    margin: -2px 0;
    margin-bottom: 0.5rem;
    font-size: 0.9em;
    color: #666; 
}

.Related-briefs-container::-webkit-scrollbar {
    margin-top: 20px;
    height: 5px;
    opacity: 0;
}

.Related-briefs-container:hover::-webkit-scrollbar {
    opacity: 1;
}

/* FontAwesome import */
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
