
  /* Wrapper for the related novels carousel */
  .elated-novels-wrapper {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  

  .Related-novels-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
  

