.audio-player {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: #f8f8f8;
    min-width: 600px;
    margin: 20px auto;
  }
  
  .audio-player button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }
  
  .audio-player button:focus {
    outline: none;
  }
  
  .audio-player .play-pause-btn {
    font-size: 1.5em;
  }
  
  .audio-player .mute-btn {
    font-size: 1em;
  }
  
  .audio-player .skip-btns {
    font-size: 1em;
  }
  
  .audio-player input[type="range"] {
    width: 100%;
    cursor: pointer;
  }
  
  .audio-player .time {
    font-size: 0.8em;
    color: #333;
    width: 100px;
    text-align: center;
  }
  
  /* Custom styles for the progress bar */
  .audio-player input[type="range"] {
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
    width: 100%;
    height: 2px;
    background: #9e4700; 
    outline: none; 
    opacity: 0.7; 
    transition: opacity 0.2s;
    border-radius: 10px;
  }
  
  .audio-player input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; 
    appearance: none;
    width: 8px; 
    height: 8px; 
    background: #8a4a00; 
    cursor: pointer; 
    border-radius: 50%;
  }
  

  

  