.FeatArch-header {
  background-color: #765623; /* Light grey background */
  margin: 0;
  width: 100%;
  height: 35px;
  text-align: left; /* Center the title text */
  display: flex;
  padding-left: 2rem;
  box-sizing: border-box;
  align-items: center;
  gap: 6px;
}

.FeatArch-header p {
    margin: 0; /* Remove default paragraph margin */
    padding: 5px;
    color: #ffffff; 
  }

.FeatArch-Container {

}

.FeatArch-content {
  display: flex;
  padding: 1rem 2rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  align-self: stretch;

}

#FeatArchTitle {
  text-align: center;
  margin: 0;
}

#FeatArchImg {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#FeatArchP {
  color: var(--Body-Text-Body-Color, #1A1A1A);

  /* Body/Regular */
  font-family: Avenir;
  font-size: 1rem;
  font-style: normal;
  font-weight: 350;
  line-height: 130%; /* 1.3rem */
  letter-spacing: 0.05rem;
                                  
  margin: 0;
}