/* DataVis.css */
.DataVis {

    background: url("/public/datavis1.png") , lightgray 50% / cover no-repeat;
    background-blend-mode: difference;
    background-size: auto;
    background-position: center;
    width: 100%;
    height: 23.88rem;
}

.DataVis-header {
    background-color: #765623; /* Light grey background */
    margin: 0;
    width: 100%;
    height: 35px;
    text-align: left; /* Center the title text */
    display: flex;
    padding-left: 2rem;
    box-sizing: border-box;
    align-items: center;
    gap: 6px;
}

.DataVis-header p {
    margin: 0; /* Remove default paragraph margin */
    padding: 5px;
    color: #ffffff; 
  }


.DataVis-content {
    display:flex;
    justify-content: center;
    align-items: center;
    height: calc(23.88rem - 35px);
    text-align: center;
}

#DataVis-Button {
    display: flex;
    width: 173px;
    padding: 1.5rem;
    align-items: flex-start;
    gap: 0.5rem;

    border-radius: 0.5rem;
    color: var(--Body-Text-Body-Color, #1A1A1A);
    text-align: center;
    text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.20);

    /* Body/Bold */
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.8px;
}