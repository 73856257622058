/* PodcastCarousel.css */
.PodcastFilter {
    font-family: Arial, sans-serif;
}

.podcast-slide {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    margin: 15px;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    position: relative;
}

.podcast-slide img {
    width: 100%;
    border-radius: 8px 8px 0 0;
    height: auto;
    display: block; /* Removes bottom space/gap */
}

.podcast-slide h3 {
    margin: 10px 0;
    font-size: 16px;
    color: #333;
}

/* Slick slider arrows customized */
.slick-prev,
.slick-next {
    z-index: 50;
    top: 50%;
    width: 30px;
    height: 30px;
    transform: translate(0, -50%);
}

.slick-prev {
    left: 10px;
}

.slick-next {
    right: 10px;
}

.slick-prev:before,
.slick-next:before {
    font-size: 30px;
    color: black; /* Arrow color */
}

/* Dots customization */
.slick-dots {
    bottom: -30px;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick-dots li {
    margin: 0 3px;
}

.slick-dots li button:before {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.75); /* Dots color */
}

.slick-dots li.slick-active button:before {
    color: black; /* Active dot color */
}

/* Responsive adjustments for smaller devices */
@media (max-width: 768px) {
    .slick-prev,
    .slick-next {
        width: 20px;
        height: 20px;
    }

    .slick-prev:before,
    .slick-next:before {
        font-size: 20px;
    }

    .podcast-slide h3 {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .slick-prev {
        left: 5px;
    }

    .slick-next {
        right: 5px;
    }
}

.image-wrapper {
    position: relative; /* Allows absolute positioning for the length-badge */
}




.length-badge {
    position: absolute;
    bottom: 0.2rem; /* Space from the bottom of the image */
    left: 0px; /* Space from the left of the image */
    background: #D9D9D9;
    color: white;
    padding: 0.25rem 0.375rem;
    font-size: 12px;

    z-index: 1; /* Ensure the badge is on top of the image */
    color: var(--Body-Text-Body-Color, #1A1A1A);

/* Body/Regular */
font-family: Avenir;
font-size: 1rem;
font-style: normal;
font-weight: 350;
line-height: 130%; /* 1.3rem */
letter-spacing: 0.05rem;
}

  