.Podcast-header {
  background-color: #765623; /* Light grey background */
  margin: 0;
  width: 100%;
  height: 35px;
  text-align: left; /* Center the title text */
  display: flex;
  padding-left: 2rem;
  box-sizing: border-box;
  align-items: center;
  gap: 6px;
}


.Podcast-header p {
  margin: 0; /* Remove default paragraph margin */
  padding: 5px;
  color: #ffffff;
}

.highlight {
  color: var(--Theme-Primary, #765623);
  text-decoration: none;
}
.highlight:hover {
  text-decoration: underline;
}


.Podcast-content {
  justify-content: center;
  padding: 32px;
  background-color: white;
}

.Podcast-content p {

  /* Body/Regular */
  font-family: Avenir, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 130%; /* 20.8px */
  letter-spacing: 0.8px;
  text-wrap: pretty;
}

.Podcast-content img {
  display: block;
  margin-left: auto;
  margin-right: auto;

  margin-top: -25px;
}

body .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 100;
  background: transparent;
  overflow: hidden;
}

body .card .current-song {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #071739;
  background: transparent;
}

body .card .current-song audio {
  display: none;
}

body .card .current-song .song-name {
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 22px;
}

body .card .current-song .time {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 240px;
}

body .card .current-song #timeline {
  position: relative;

  width: 120%;
  height: 2px;
  background: #765623;
  border-radius: 5px;
  cursor: pointer;
}

body .card .current-song #playhead {
  position: absolute;
  top: 50%;
  left: 0;
  width: 10px;
  height: 10px;
  background: #765623;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

body .card .current-song .controls {
  margin-top: 10px;
}

body .card .current-song .controls button {
  font-size: 18px;
  text-align: center;
  transition: 0.2s;
  cursor: pointer;
  border: transparent;
  background: 0;
  color: #765623;
}

body .card .current-song .controls button.play {
  width: 50px;
  height: 50px;
  transform: translateY(-30px);
}

body .tagbuttons {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
}
