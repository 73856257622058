/* Slider.css */
.mySwiper{
  height: 24.1875rem;
  margin-top: 1.51rem;
  margin-bottom: 2rem;
  margin-left: 5vw;
  margin-right: 5vw;
  max-width: 90vw;
}
  
.mySwiper .swiper-button-next::after, .mySwiper .swiper-button-prev::after {
    font-size: 30px;
    color:#fff;
}

  .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
  }
  
#Slider1 {
  display: flex;
  flex-direction: column;
  gap: 50px;
  text-align: center;
  justify-content: center;
  width: 100%; /* Adjust based on your slider's width */
  height: auto; /* Adjust based on your slider's height */
  background-image: url('/public/banner.png'); /* Path to your image */
  background-position: right center; /* Align the right half of the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-color: black;
}

#Slider1 p {
  margin-top: -10px;
  color: var(--Gray-White, #FFF);
  text-align: center;

  /* Heading/H1 */
  font-family: Recoleta;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 48px */
  letter-spacing: 0.8px;
  text-transform: capitalize;

  
}

#Slider1 button {
  color: var(--Gray-White, #FFF);
  text-align: center;

  /* Body/Regular */
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  letter-spacing: 0.8px;
  background-color: transparent;
  border-radius: 4px;
  border: 0.5px solid var(--Gray-White, #FFF);
  padding: 16px 24px;

  width: 250px;

  cursor: pointer;
}

.swiper-pagination-bullet {
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(255, 255, 255, 0.2);
}
.swiper-pagination-bullet-active {
  color: #fff;
  background: #dbe5f0;
}