.PodcastFilter {
    display: flex;
    min-width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    min-width: 1400px;
  }
  
  .Podcast-Filter-bar {
    display: flex;
    padding: 1.5rem 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    height: auto;
  }
  
  .Podcast-Results {
    padding: 20px; /* Adjust the padding as needed */
    margin: 10px 0 0 10px;
    width: 73%;
    flex-wrap: wrap;
    display: flex;
  }
  
  .Podcast-R {
    margin: 10px; /* Remove margin */
    font-size: 0.9em; /* Adjust font size if needed */
    color: #666;
    text-wrap: wrap;
    width: 150px;
  }
  
  .PodcastSearchContainer {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #ccc; /* Creates the underline */

    width: 100%;
  }
  
  .PodcastSearchBox {
    border: none;
    outline: none;
    font-size: 1rem;
    flex-grow: 1; /* Ensures input takes up the available space */
    margin-right: -1px; /* Aligns the button with the input */
    background-color: transparent;

  
  }

  .Podcast-info {
    margin-top: 20px;
    margin-left: 10px;
  }

  .Podcast-info p{
    margin-left: 30px;
  }

  .Podcast-render {
    margin-left: 4.94rem;
    margin-top: 5.12rem;
    width: 73%;
  }

.topic-button-container {
  display: flex;

  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
}

.information {
  display:flex;
  width: 100%;
}

.pod-title {
  font-size: 40px;
  font-family: Recoleta;
}

.pod-disc {
  font-size: 16px;
}

.pod-info-right {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
}


.pod-info-right-meta {
  display: flex;
  gap: 22px;
  font-size: 20px;
}

.player-wrapper {
  display: block;
}

.pod-caro-wrapper {

  padding: 0;
}

.transcript{
  padding-left: 35px;
  padding-right: 35px;

}

.tran-text {
  font-size: 13px;
}

.tran-button-wrap {
  text-align: center;
}

.tran-button-wrap button {
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: none;
  border-radius: 3px;
  border-width: 1px;
  border-color: #5d3100;
  cursor: pointer;

}

.searched-pod {
  margin-left: 4.94rem;
  margin-top: 5.12rem;
  width: 73%;
  gap: 2.19rem;
  display: flex;
  flex-direction: column;
}
.filtered-podcasts {

  gap: 5%;

  flex-wrap: wrap;
  display: flex;
}

.filtered-podcast-item {
  width: 18%;
  text-wrap: wrap;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.filtered-podcast-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.filtered-podcast-title {
  color: var(--Body-Text-Body-Color, #1A1A1A);

/* Body/Italic */
font-family: Avenir;
font-size: 1rem;
font-style: normal;
font-weight: 350;
line-height: 130%; /* 1.3rem */
letter-spacing: 0.05rem;

margin: 0;
}

.filtered-podcast-date {
  color: var(--Body-Text-Body-Color, #1A1A1A);

/* Body/Regular */
font-family: Avenir;
font-size: 1rem;
font-style: normal;
font-weight: 350;
line-height: 130%; /* 1.3rem */
letter-spacing: 0.05rem;

margin: 0;
}

.filtered-podcast-host {
  color: var(--Body-Text-Body-Color, #1A1A1A);

  /* Body/Regular */
  font-family: Avenir;
  font-size: 1rem;
  font-style: normal;
  font-weight: 350;
  line-height: 130%; /* 1.3rem */
  letter-spacing: 0.05rem;
  
  margin: 0;
}

.searched-pod-heading {
  margin: 0;
}