/* FeatNovel.css */

.FeatNovel-Container {
    height: 34.88rem;
}

.FeatNovel-header {
    background-color: #765623; /* Light grey background */
    margin: 0;
    width: 100%;
    height: 35px;
    text-align: left; /* Center the title text */
    display: flex;
    padding-left: 2rem;
    box-sizing: border-box;
    align-items: center;
    gap: 6px;
}

.FeatNovel-header p {
    margin: 0; /* Remove default paragraph margin */
    padding: 5px;
    color: #ffffff; 
}

.tagbuttons {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
    text-wrap: nowrap;
}

.HomeFeatNolButtonContainer {
    display: flex;
    padding: 3rem 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;

    display: flex;
align-items: flex-start;
align-content: flex-start;
align-self: stretch;
flex-wrap: wrap;
}

.FeatNovel-content {
    height: 32.13rem;
}

.FeatNovl {
    display: block;
    font-size: 18px;
    background: whitesmoke;

    height: 100%;
    position: relative;

}

.FeatNovl .swiper-button-next, .FeatNovl .swiper-button-prev {
    top: 35%;
}

.FeatNovl .swiper-button-next::after, .FeatNovl .swiper-button-prev::after {
    font-size: 20px;
    color:#765623;
}

.FeatSlides {
 display: flex;
 flex-direction: column;
}

.FeatSlides img {
    width: 11rem;
    height: 17.375rem;
    margin-top: 2rem;
}

.HomeFeatNolButtonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px; /* Adjust the gap between buttons as needed */
    justify-content:left; /* Center the buttons */
}

.FeatNovelButton {
    display: flex;
    padding: 6px 12px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 0.25px solid var(--Gray-Black, #000);
    background-color: transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    font-size: 1rem;
    font-family: Avenir, sans-serif;
    font-style: normal;
    line-height: 130%;
    font-weight: 350;
    letter-spacing: 0.05rem;
}

.titleofbookHome {
    color: var(--Theme-Primary, #765623);
text-align: center;

/* Body/Bold */
font-family: Avenir;
font-size: 1rem;
font-style: normal;
font-weight: 900;
line-height: 130%; /* 1.3rem */
letter-spacing: 0.05rem;
}