/* RelatedArchives.css */
.Related-arch-wrapper {
    display: flex;
    align-items: center;
}

.section-title-related {
    display: flex;
    text-shadow: 0 2px 5px rgba(0,0,0,0.2);
    background-color: #765623;
    text-align: left;
    margin: 0;
    color: #fff;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    padding: 0.5rem 1.5rem;
    align-items: center;
    gap: 1rem;
}

.topic-buttons-container {

}

.Related-arch-container {
    flex-grow: 1;
    overflow-x: auto;
    padding: 10px 0 0 25px;
    white-space: nowrap;
    display: flex;
    width: 100%;
    scroll-behavior: smooth;
}

.nav-button {
    flex: 0 0 auto;
    background-color: transparent;
    border: none;
    color: #000; /* Adjust the color as needed */
    cursor: pointer;
    transition: color 0.3s ease;
    font-size: 30px;
}

.nav-button:hover {
    color: #555; /* Darker shade on hover */
  }





.Related-arch {
    margin-right: 20px;
    flex-basis: 18%; /* Adjust width as needed */
    text-align: left;
}

.Related-arch img {
    width: 125px;
    height: 125px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.Related-arch-info {
    padding: 2px 0;
    display: flex;
    flex-direction: column;
    width: 150px;
    text-wrap: wrap;
}

.Related-arch h3 {
    margin: 10px 0;
    font-size: 1.2em;
    text-wrap: wrap;
    text-align: left;
    display: inline-block;
}


.Related-arch p {
    margin: -2px 0;
    font-size: 0.9em;
    color: #666; /* Grey text for publication date and author name */
}


.Related-arch-container::-webkit-scrollbar {
    margin-top: 20px;
    height: 5px;
    opacity: 0;
}

.Related-arch-container:hover::-webkit-scrollbar{
   opacity: 1;
}