.AboutUs {
    display: flex;
    width: 90vw;
    max-width: 1920px;
    margin-left: 5vw;
    margin-right: 5vw;
}

.aboutusrender {
    /* display: flex;
    flex-direction: column; */
    margin-left: 4.94rem;
    margin-top: 5.12rem;
    width: 73%;
}

.aboutsub {
    display: flex;
    gap: 1rem;
    color: var(--Body-Text-Body-Color, #1A1A1A);

    /* Text Shadow */
    text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.20);

    /* Heading/H5 */
    font-family: Recoleta;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 1.5rem */
    letter-spacing: 0.0125rem;
}

.AboutUs-Filter-bar {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    height: auto;

    
}



.AboutUsFilters {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.AboutUsFilters a {
    text-decoration: none;
    color: var(--Theme-Primary, #765623);

    /* Heading/H5 */
    font-family: Recoleta;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 1.5rem */
    padding: 0.5rem 0;
    transition: color 0.2s ease-in-out;
}

.AboutUsFilters a:hover {
    color: #007acc; /* Optional: changes the link color on hover */
    text-decoration: underline;
}

.aboutushow {

}

.image-containerabout {
    text-align: center;
}

.Info-Img-div-about {
    display: flex;
    justify-content: flex-start; /* Center-aligns the items horizontally */
    align-items: center; /* Center-aligns the items vertically */
    gap: 5rem; /* Adjusts spacing between funders */
    flex-wrap: wrap; /* Ensures responsiveness for smaller screens */
    margin: 1rem 0; /* Adds margin around the section */
}

.abt-peoples {
    display: flex;
    flex-direction: column;
    width: 33.33333333333333%;
}

.abt-peopleContainer {
    display: flex;
    align-items: flex-start;
    gap: 4rem;
    align-self: stretch;
}

.abt-peopleContainer img {
    display: flex;
    width: 100%;
}

.abt-people-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    align-self: stretch;
}

.abt-people-photos {
    width: 100%;
    position: relative;
}

.abt-description {
    position: absolute;
    bottom: 0; /* Place at the bottom of the image */
    left: 0; /* Start from the left edge */
    width: 100%; /* Span across the image */
    background: rgba(118, 86, 35, 0.85);
    text-align: center; /* Center-align text */
    margin: 0;

    color: var(--Gray-White, #FFF);

    /* Heading/H6 */
    font-family: Avenir;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 1.5rem */
    letter-spacing: 0.2rem;
    text-transform: uppercase;

    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
  }

.abt-people-name {
    color: var(--Body-Text-Body-Color, #1A1A1A);

    /* Heading/H3 */
    font-family: Recoleta;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 2.1rem */
    margin-bottom: 0;
    
    align-self: stretch;
}

.abt-people-title {
    margin: 0;
    color: var(--Body-Text-Body-Color, #1A1A1A);

    /* Body/Italic */
    font-family: Avenir;
    font-size: 1rem;
    font-style: italic;
    font-weight: 350;
    line-height: 130%; /* 1.3rem */
    letter-spacing: 0.05rem;
    align-self: stretch;
}

.abt-people-short {
    color: var(--Body-Text-Body-Color, #1A1A1A);

    /* Body/Regular */
    font-family: Avenir;
    font-size: 1rem;
    font-style: normal;
    font-weight: 350;
    line-height: 130%; /* 1.3rem */
    letter-spacing: 0.05rem;
}


.aboutsub-partnered-institutions {
    margin-bottom: 2rem;
}

.about-partner-image-container {
    display: flex;
    justify-content: flex-start; /* Centers the logos horizontally */
    align-items: center; /* Ensures all logos are vertically aligned */
    gap: 4rem; /* Adjust spacing between logos */
    padding: 0; /* Adds padding around the container */
    text-align: center; /* Centers text under each logo */
    margin-bottom: 1rem;
}

.abt-partners {
    display: flex;
    flex-direction: column; /* Stacks the logo and text */
    align-items: center; /* Centers content within each item */
}

.abt-partners img {
    max-width: 120px; /* Restricts logo size for consistency */
    height: auto; /* Maintains aspect ratio */
}

.abt-partners p {
    margin: 0; /* Removes extra margins around the text */
    font-family: Arial, sans-serif; /* Ensures consistent font */
    font-size: 14px; /* Adjust text size as needed */
}

.abt-partners p:nth-child(2) {
    margin-top: 0.5rem; /* Adds space between the image and first text line */
}

.abt-partners p:nth-child(3) {
    font-style: italic; /* Ensures the third line is italicized */
    margin-top: 0.2rem; /* Fine-tunes spacing between lines */
}

.abt-partners img{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.abt-partners p {
    color: #000;
    text-align: center;

    /* Heading/H5 */
    font-family: Recoleta;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 1.5rem */
    letter-spacing: 0.0125rem;
}

.abt-funding-container {
    display: flex;
    justify-content: flex-start; /* Center-aligns the items horizontally */
    align-items: center; /* Center-aligns the items vertically */
    gap: 5rem; /* Adjusts spacing between funders */
    flex-wrap: wrap; /* Ensures responsiveness for smaller screens */
    margin: 1rem 0; /* Adds margin around the section */
}

.abt-funders p {
    color: #000;
    text-align: center;

    /* Heading/H5 */
    font-family: Recoleta;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 1.5rem */
    letter-spacing: 0.0125rem;
}

.aboutsub-funding-agencies {
    margin-bottom: 2rem;
}

.abt-Info-Content {
    color: var(--Body-Text-Body-Color, #1A1A1A);

    /* Body/Regular */
    font-family: Avenir;
    font-size: 1rem;
    font-style: normal;
    font-weight: 350;
    line-height: 130%; /* 1.3rem */
    letter-spacing: 0.05rem;
}

.abt-names-ul {
    margin-top: -15px;
    margin-bottom: 0;
    list-style: none;

}

.abt-names-li {
    margin-bottom: 0.5rem;
    font-size: 1rem;
}

.abt-names-li a {
    color: var(--Theme-Primary, #765623);
    /* Body/Regular */
    font-family: Avenir;
    font-size: 1rem;
    font-style: normal;
    font-weight: 350;
    line-height: 130%; /* 1.3rem */
    letter-spacing: 0.05rem;
}

.abt-filter-bar {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    color: var(--Body-Text-Body-Color, #1A1A1A);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: Avenir;
    font-size: 1rem;
    font-style: normal;
    font-weight: 350;
    line-height: 100%;
    letter-spacing: 0.8px;
    max-width: 390px;
    min-width: 390px;
}

.active-filter {
    color: #1A1A1A !important;/* or whatever color you prefer */

}
  