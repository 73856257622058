.FeatNotes-header {
  background-color: #765623; /* Light grey background */
  margin: 0;
  width: 100%;
  height: 35px;
  text-align: left; /* Center the title text */
  display: flex;
  padding-left: 2rem;
  box-sizing: border-box;
  align-items: center;
  gap: 6px;
}

.FeatNotes-header p {
    margin: 0; /* Remove default paragraph margin */
    padding: 5px;
    color: #ffffff; 
  }


.FeatNotes-content {
  display: flex;
  padding: 3rem 2rem;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  align-self: stretch;
  background-color: white;
  height: 15.63rem;
}

.FeatNotes-content p {
  margin: 0;
}

.FeatNotes-tagbuttons {
  display: flex;
  gap: 4px;
  text-wrap: nowrap;
  flex-wrap: wrap;
  width: 100%;
}