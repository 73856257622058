html {
  font-size: 100%;
}

.filter-bar-header {
    background-color: #765623;
    text-align: center;
    margin: 0;
    padding: 0.625rem 2rem;
    color: var(--Gray-White, #FFF);
    gap: 1rem;
    display: flex;
    align-items: center;
    align-self: stretch;

    font-family: Recoleta;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0.0125rem;
}

.filter-bar-content {
    display: flex;
    padding: 1.5rem 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
}

.filter-heading {
    color: var(--Body-Text-Body-Color, #1A1A1A);
    font-family: Avenir;
    font-size: 1rem;
    font-weight: 900;
    line-height: 130%;
    letter-spacing: 0.05rem;
    margin-left: 1.7rem;
    cursor: pointer;
}

label {
    color: var(--Body-Text-Body-Color, #1A1A1A);
    font-family: Avenir;
    font-size: 1rem;
    font-weight: 350;
    line-height: 130%;
    letter-spacing: 0.05rem;
    gap: 8px;
    align-self: stretch;
}

input[type="checkbox"]:checked {
    accent-color: #765623;
    margin-right: 10px;
}

input[type="checkbox"] {
    margin-right: 10px;
}

.collapsible-header {
    cursor: pointer;
    font-weight: 900;
    padding: 10px;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapsible-content {
    padding: 10px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.search-input {

}

.search-button {

}

.search-button:hover {
    background-color: #e6cb91;
}
