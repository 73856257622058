/* Info.css */
@import url('https://fonts.cdnfonts.com/css/avenir');

.Info-header{
    margin-top: -15px;
}

.Info-Img-div {
    display: flex;
    justify-content: space-around;
    align-items: flex-start; /* Aligns items at the start */
}

.image-container {
    text-align: center; 
    width: 456px;
}

.image-container img {
    display: block; 
    margin: 0 auto; 
    max-width: 100%; 
}

.image-container p {
    margin-top: 10px; 
    font-size: 14px; 
    color: #333; 
}

#info-top-desc {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0px;
    padding-bottom: 20px;
}

.Info-Title {
    display: block;
    background-color: rgba(255, 255, 255, 0.88);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.225);
}

.Info-Content-Top {
    padding-left: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 50px;
    font-family: 'Avenir', sans-serif;
    font-size: 14px;                   
}

.Info-Content-Bottom {
    font-family: 'Avenir', sans-serif;
    font-size: 14px;                   
}

#Info-Content-Top-Button {
    background-color:transparent;
    color: rgba(0, 0, 0, 0.612);
    border: 2px solid #e7e7e7;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;
    border: 1px solid black;
    border-radius: 5px;
    transition-duration: 0.4s;
    cursor: pointer;
}

.Info-Content {
    font-family: 'Avenir', sans-serif;
}

.Info-Content p {

    font-family: 'Avenir', sans-serif;
    font-size: 14px;   
}

  
#Info-Content-Top-Button:hover {background-color: #e7e7e7;}

.InfoPanel2 {
    display: flex;
    justify-content: space-between;
}