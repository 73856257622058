/* Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .navbar a {
    color: #333;
    text-decoration: none;
    padding: 0.5rem 0.375rem;
    font-size: 14px; 
    text-transform: uppercase; 
    max-height: 20px;
  }
  
  .navbar a:not(.logo-link):hover,
  .navbar a:not(.logo-link).active {
    border-bottom: 2px solid #333; 
  }
  
.finalHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90vw;
  
  margin-left: 5vw;
  margin-right: 5vw;
}
  

  .nav-links {
    display: flex;
    align-items: flex-end;
    gap: 2rem;
  }

  .social-icons{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    margin-top: 3rem;
  }
  
  .social-icon {

    font-size: 24px; 
    color: #765623;
  }

  .x {
    background-image: './Vector.png';
  }
  

  @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
  

