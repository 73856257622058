html {
  font-size: 100%; /* Fallback for older browsers */



}

.filter-bar-header {
    background-color: #765623; /* Light grey background */
    text-align: center; /* Center the title text */
    margin: 0;
    padding: 0.625rem 2rem;
    color: var(--Gray-White, #FFF);
    gap: 1rem;
    display: flex;
    align-items: center;
    align-self: stretch;

    /* Heading/H5 */
    font-family: Recoleta;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 1.5rem */
    letter-spacing: 0.0125rem;
  }
  
.focusBook {
  margin-left: 4.94rem;
  margin-top: 5.12rem;
  width: 73%;
}

.filtjsx {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.filter-heading {
  height: 1rem;
  flex: 1 0 0;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  color: var(--Body-Text-Body-Color, #1A1A1A);

  /* Body/Bold */
  font-family: Avenir;
  font-size: 1rem;
  font-style: normal;
  font-weight: 900;
  line-height: 130%; /* 1.3rem */
  letter-spacing: 0.05rem;
  margin-left: 1.7rem;
}


label {
  color: var(--Body-Text-Body-Color, #1A1A1A);

  /* Body/Regular */
  font-family: Avenir;
  font-size: 1rem;
  font-style: normal;
  font-weight: 350;
  line-height: 130%; /* 1.3rem */
  letter-spacing: 0.05rem;

gap: 8px;
align-self: stretch;
}

.arrow{
  font-size: small;
  color: #765623;
}

h3 {
  display: flex;
  justify-content:space-between;
}

input[type="checkbox"]:checked {
    accent-color: #765623;
    margin-right: 10px;
  }

input[type="checkbox"] {
  margin-right: 10px;
}
  
.scrollable-section {
    max-height: 142px; /* Adjust the height as needed */
    overflow-y: auto; 

    color: var(--Body-Text-Body-Color, #1A1A1A);

/* Body/Regular */
font-family: Avenir;
font-size: 1rem;
font-style: normal;
font-weight: 350;
line-height: 130%; /* 1.3rem */
letter-spacing: 0.05rem;

display: flex;
flex-direction: column;
align-items: flex-start;
gap: 0.375rem;
align-self: stretch;

}

.scrollable-section input{
  
}
  .lucky-button {
    padding: 8px 16px;
    margin-top: 10px; /* Adjust based on layout */
    background-color: #caba71; /* Example: blue color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    /* Other styling as needed */
  }
  
  .lucky-button:hover {
    background-color: #e6cb91; /* Darker shade for hover effect */
  }
  
  .content {
    display: flex;
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    
  }
  
  .filter-bar-content {
    display: flex;
    padding: 1.5rem 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    height: auto;
  }
  
  .filter-bar {/* Adjust the width as needed */
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff; /* Optional: sets a background color for the filter bar */
    /* box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Optional: adds a subtle shadow */

    color: var(--Body-Text-Body-Color, #1A1A1A);

    display: flex;
    flex-direction: column;
    align-items: flex-start;

  /* Body/Regular */
    font-family: Avenir;
    font-size: 1rem;
    font-style: normal;
    font-weight: 350;
    line-height: 100%; /* 20.8px */
    letter-spacing: 0.8px;
    max-width: 390px;
    min-width: 390px;
  }
  
  .search-results { /* Adjust the width as needed */
    margin-left: 4.94rem;
    margin-top: 5.12rem;
    width: 73%;
  }

  
  body {
    background-color: #edede7;
    font-family: "Nunito", sans-serif;
  }
  
  .logo {
    height: 50px;
    transform: translateY(-50%);
  }
  
  
  .header {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #edede7;
  }
  
  .logoImage{
    flex-shrink: 0;
  }
  


.featured-novels-container {
    display: flex;
    overflow-x: auto;
    gap: 2rem;
    white-space: nowrap;
    flex-grow: 1;
    align-items: flex-start;
    width: auto;
    justify-content: space-between;
}
  
.featured-novels-container::-webkit-scrollbar {
  margin-top: 20px;
  height: 5px;
  opacity: 0;
}

.featured-novels-container:hover::-webkit-scrollbar{
 opacity: 1;
}

  .featured-subtitle {
    color: var(--Body-Text-Body-Color, #1A1A1A);

    /* Text Shadow */
    text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.20);

    /* Heading/H5 */
    font-family: Recoleta;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 1.5rem */
    letter-spacing: 0.0125rem;
    margin-top: 0;
    margin-bottom: 2rem;

    display: flex;
    align-items: center;
    gap: 1rem;
    align-self: stretch;

    
  }

  .featured-subtitle img {
    width: 1.375rem;
    height: 1.375rem;
  }
  
  .featured-novel {
    text-align: left;
  }
  
  .featured-novel img {
    width: 12rem;
    height: 18.5rem;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    margin-bottom: 5px; /* Reduced margin */
  }
  
  .featured-novel-info {
    padding: 2px 0;
    display: flex;
    flex-direction: column;
    width: 150px;
    text-wrap: wrap;
  }
  
  .featured-novel-info h3 {
    margin-bottom: 5px; /* Reduced margin */
  }
  
  .featured-novel-info p {
    margin: 0; /* Remove margin */
    font-size: 0.9em; /* Adjust font size if needed */
    color: #666;
  }

/* Styles for the container that holds individual book details */
.search-results-container {
  display: flex;
  padding: 32px 32px;
  width: 100%;
  flex-wrap: wrap;
  gap: 32px;
}

.searched-novel {
  text-align: left;
}

.searched-novel img {
  width: 12rem;
  height: 18.5rem;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  margin-bottom: 5px; /* Reduced margin */
}

.novel-info {
  padding: 2px 0;
  display: flex;
  flex-direction: column;
  width: 12rem;
  text-wrap: wrap;
}

.novel-info p {
color: var(--Body-Text-Body-Color, #1A1A1A);

/* Body/Italic */
font-family: Avenir;
font-size: 1rem;
font-weight: 350;
line-height: 130%; /* 1.3rem */
letter-spacing: 0.05rem;
padding: 0;
margin: 0;
}