.BriefsWrapper {
    display: flex;
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
}

.BriefsiFrameWrapper {
    padding: 20px; /* Adjusted padding */
    margin: 10px 0 0 10px;
    width: 80%; /* Adjust width as necessary */
}

.PopupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(255, 255, 255); /* Darkened the background for better focus on the popup */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the overlay is above other content */
}

.PopupContent::-webkit-scrollbar {
    display: none;
}

.PopupContent {
    position: relative;
    width: 95%; /* Adjusted to 80% for a bit more margin */
    height: 95%; /* Maintain */
    background-color: rgba(255, 255, 255, 0.685);
    overflow: auto; /* In case the content overflows */
    border-radius: 8px; /* Optional: Adds rounded corners for a softer look */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for depth */
}


.CloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: #f44336; /* Red color for visibility */
    color: white; /* White text color */
    font-size: 24px;
    cursor: pointer;
    border-radius: 50%; /* Circle shape */
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Additional styles for responsiveness and aesthetics */
@media (max-width: 768px) {
    .PopupContent {
        width: 95%; /* More screen space on smaller devices */
    }
}

.BriefsFilter {
    display: flex;
    min-width: 90vw;
  }
  
  .Briefs-Filter-bar {
    display: flex;
    padding: 1.5rem 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    height: auto;
  }
  
  .Briefs-Results {

    flex-wrap: wrap;
    display: flex;
    gap: 1rem;
  }
  
  .Briefs-R {

    font-size: 0.9em; /* Adjust font size if needed */
    color: #666;
    text-wrap: wrap;
    width: 150px;

  }
  
  .BriefsSearchContainer {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #ccc; /* Creates the underline */

    width: 100%;
  }
  
  .BriefsSearchBox {
    border: none;
    outline: none;
    font-size: 1rem;
    flex-grow: 1; /* Ensures input takes up the available space */
    margin-right: -1px; /* Aligns the button with the input */
    background-color: transparent;
  
  }
.BriefsToggleButtons {
  display: flex;
  gap: 0.5rem;
  text-wrap: nowrap;
  flex-wrap: wrap;
}
  .Briefs-info p {
    margin: 0;
  }


  .Briefs-render {
    display: flex;
    flex-direction: column;
    margin-left: 4.94rem;
    margin-top: 5.12rem;
    width: 73%;
  }

  .multi-threaded {
    margin-bottom: -15px;
  }

