.footer {
  color: #fff;
  font-family: Arial, sans-serif;
  width: 90vw;
  height: 19.4375rem;
  flex-shrink: 0;
  margin-left: 5vw;
  margin-right: 5vw;
  }

.footer-top {
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: #fff;
  height: 10rem;
}

.footer-first-half {
  display: flex;
  margin-left: 20px;
}

.logo-foot {

margin-right: 30px;
transform: translateY(+20%);
}

/* Container for the subscription form and social icons */
.subscription-social-container {
  display: flex;
  align-items: center;
  color: var(--Gray-White, #FFF);
  text-align: center;
  leading-trim: both;
  text-edge: cap;

  /* Body/Nav Text */
  font-family: Avenir;
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin: 0;
}

.subscription-social-container p {
  margin: 0;
  margin-top: -30px;
}

.footer-subscribe {
  display: flex;
  flex-direction: column;
  margin-right: 20px; /* Adjust the margin as needed */
}

.footer-subscribe p {
  margin-bottom: 10px;
}

.footer-subscribe input {
  padding: 10px;
  margin-bottom: 10px; /* Space between input and button */
}

.footer-subscribe button {
  padding: 10px;
  cursor: pointer;
  background-color: #444;
  border: none;
  color: #fff;
}

.footer-subscribe button:hover {
  background-color: #333;
}

.footer-social {
  display: flex;
  align-items: center;
  color: #fff;
}

.footer-social a {
  color: #fff;
  margin-left: 10px; /* Adjust the margin as needed */
  font-size: 1.5em; /* Larger icons */
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #000;
}

.footer-links p {
  color: var(--Gray-White, #FFF);
  text-align: center;
  leading-trim: both;
  
  text-edge: cap;
  /* Body/Nav Text */
  font-family: Avenir;
  font-size: 18px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin-top: 10px;
}
.fund-img {
  display: flex;
}

.footer-funding img, .footer-partners img {
  margin-right: 10px;
}

.footer-first-half-left {
  display: flex;
  flex-direction: column;
}

.social-icons-footer a:link, 
.social-icons-footer a:visited, 
.social-icons-footer a:hover, 
.social-icons-footer a:active {
  color: white; /* Keeps the color the same for all states */
}



.social-icons-footer {
  color: white;
  display: flex;
  gap: 1.5rem;
}

.footer-funding img {
  /* width: 30%;
  height: auto; */
}

.foot-partner-img{
  display: flex;
}

.footer-funding{
  margin-left: 15px;
}

.footer-partners{
  margin-left: 50vw;
}



@media (max-width: 768px) {
  .footer-top, .footer-bottom {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-links, .footer-funding, .footer-partners {
    margin-bottom: 20px;
  }

  .subscription-social-container {
    flex-direction: column;
  }

  .footer-social {
    margin-top: 10px; /* Add some space between the form and icons on small screens */
  }
}

@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
