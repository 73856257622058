#caci-form-wrapper {
    display: flex;
    margin-top: 5vh;
    margin-bottom: 5vh;
    
}
#caci-form-main {
    display: flex;
    width: 41.5rem;
    flex-direction: column;
    align-items: flex-start;

    background: var(--Theme-Day-background, #EDEDE7);

    /* Regular Shadow */
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);

    margin-left: auto;
    margin-right: auto;
    border: 1px solid var(--Theme-Primary, #765623);
}

.inner {
    width: 100%;
}

.major {
    display: flex;
    padding: 1rem 1.5rem;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;

    background: var(--Theme-Primary, #765623);
    maxi-width: 100%;

    color: var(--Gray-White, #FFF);

    /* Heading/H1 */
    font-family: Recoleta;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 3rem */
    letter-spacing: 0.05rem;
    text-transform: capitalize;
}

.major p {
    margin: 0;
}

.form-content p {
    max-width: 100%;

    margin-top: 0;

    color: var(--Body-Text-Body-Color, #1A1A1A);

    /* Body/Small */
    font-family: Avenir;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 1.3125rem */
    letter-spacing: 0.05rem;
}

#four {
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
}

.form-heading {
    color: var(--Body-Text-Body-Color, #1A1A1A);

    /* Heading/H4 */
    font-family: Recoleta;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 1.8rem */
}

.row {
    display: flex;
    flex-direction: column;
}

.row-time {

    display: flex;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;

    margin-bottom: 1.5rem;

}

.row-time input{
    display: flex;
align-items: center;
align-self: stretch;

}

.form-time-heading {
    color: var(--Body-Text-Body-Color, #1A1A1A);

/* Heading/H5 */
font-family: Recoleta;
font-size: 1.25rem;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 1.5rem */
letter-spacing: 0.0125rem;
}

.col-6{
    display: flex;
    flex-direction: column;
}

.role-name{
    display: flex;
    gap: 7.5rem;

    color: var(--Body-Text-Body-Color, #1A1A1A);

    /* Body/Regular */
    font-family: Avenir;
    font-size: 1rem;
    font-style: normal;
    font-weight: 350;
    line-height: 130%; /* 1.3rem */
    letter-spacing: 0.05rem;

    
}

.role-name p {
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
    margin-left: 0;
    margin-right: 0;
}

.contributor-container {
    display: flex;
    gap: 1rem;
}

.contributor-label-container {
display: flex;
padding: 0.25rem 0.5rem;
justify-content: center;
align-items: center;
gap: 0.5rem;
border-radius: 0.2rem;
border: 1px solid var(--Theme-Primary, #765623);
background: var(--Theme-Primary, #765623);
color: #FFF;
cursor: pointer;
}

.form-access-detail {
    color: var(--Body-Text-Body-Color, #1A1A1A);

    /* Heading/H4 */
    font-family: Recoleta;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 1.8rem */
    padding-top: 1.5rem;

    border-top: 1px solid var(--Theme-Primary, #765623);
}

.col-12 input.form-button-format{
display: flex;
padding: 0.5rem 1rem;
justify-content: center;
align-items: center;
gap: 0.5rem;
align-self: stretch;

border-radius: 0.3rem;
border: 1px solid var(--Theme-Primary, #765623);
background: var(--Theme-Primary, #765623);

color: var(--Gray-White, #FFF);

/* Body/Lead */
font-family: Avenir;
font-size: 1.25rem;
font-style: normal;
font-weight: 350;
line-height: 150%; /* 1.875rem */
letter-spacing: 0.05rem;

margin-bottom: 1rem;

cursor: pointer;
}

.col-12 {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.col-13 {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}

.col-13 p {
    margin-top: 0;
    color: var(--Theme-Primary, #765623);

    /* Body/Small */
    font-family: Avenir;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 1.3125rem */
    letter-spacing: 0.05rem;
}
.col-12 input{


    display: flex;
    padding: 0.375rem 0.75rem;
    align-self: stretch;
    align-items: center;
    flex: 1 0 0;

    border-radius: 0.25rem;
    border: 1px solid var(--Gray-400, #CED4DA);
    background: var(--Gray-White, #FFF);
}